import React, { useState, useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'

import { Box, Heading, Text, Button, Space } from 'components'
import { Input, Checkbox, SubmissionFailedErrorMessage } from 'components/Forms'
import LocationSelect from 'components/LocationSelect'
import { teilverkaufLeadValidationSchema } from 'utils/forms'
import { ErrorMessage } from 'components/Forms/Errors'

import { createLead } from 'api-utils/lead'
import { useLocation } from 'context/location'
import loadable from '@loadable/component'

import { PROPERTY_TYPE_OPTIONS, SALUTATION_OPTIONS } from 'models/Options'

const CountryCodeSelect = loadable(() =>
  import('components/Forms/CountryCodeSelect')
)

const Select = loadable(() => import('components/Forms/BambusSelect'))

const LeadForm = ({ title }) => {
  const { countryCode } = useLocation()
  const [loading, setLoading] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)
  const [showError, setShowError] = useState(false)
  const [isFormTouched, setIsFormTouched] = useState(false)
  const [selectedCountry, setSelectedCountry] = useState(null)

  const {
    register,
    handleSubmit,
    setError,
    errors,
    getValues,
    setValue,
    control,
  } = useForm({
    resolver: teilverkaufLeadValidationSchema,
    defaultValues: {
      initial_signup_reason: 'offer',
      property_type: '',
      salutation: '',
      zip: null,
    },
  })

  useEffect(() => {
    register('property_type')
    register('salutation')
    register('selected_country')
  }, [])

  React.useEffect(() => {
    if (getValues('telephone')) {
      return
    }

    if (selectedCountry) {
      setValue('telephone_prefix', selectedCountry.prefix)
      return
    }

    if (countryCode) {
      setValue('telephone_prefix', countryCode === 'DE' ? '+49' : '+43')
      return
    }
  }, [selectedCountry, countryCode])

  // this sets the selected_country stored in the form needed in validation
  React.useEffect(() => {
    if (selectedCountry) {
      setValue('selected_country', selectedCountry.value)
      return
    }

    if (countryCode) {
      setValue('selected_country', countryCode)
      return
    }
  }, [selectedCountry, countryCode])

  const onSubmit = async (formData) => {
    setLoading(true)

    const telephonePrefix = formData.telephone_prefix
    const telephone = formData.telephone
    //delete the telephone & telephone_prefix from data as we don't want it spread into the payload
    delete formData['telephone_prefix']
    delete formData['telephone']

    delete formData['selected_country']

    const payload = {
      trackEvent: 'content_cta_complete',
      lead: {
        ...formData,
        ...(telephone && {
          telephone: `${telephonePrefix} ${telephone
            .replace(/\ /g, '')
            .replace(/\-/g, '')
            .replace(/\(/g, '')
            .replace(/\)/g, '')}`,
        }),
        property_country:
          selectedCountry?.value ??
          (countryCode !== 'DE' && countryCode !== 'AT' ? 'AT' : countryCode),
        application_country:
          countryCode !== 'DE' && countryCode !== 'AT' ? 'AT' : countryCode,
      },
      customEventPayload: {},
    }

    const { success, errors } = await createLead(payload)

    if (errors) {
      errors.forEach(({ name, type, message }) =>
        setError(name, { type, message })
      )
    }

    if (success) {
      setShowSuccess(true)
    } else if (!errors) {
      setShowError(true)
    }

    setLoading(false)
  }

  const onFormTouch = () => {
    setIsFormTouched(true)
  }

  useEffect(() => {
    if (isFormTouched) {
      window.dataLayer?.push({
        event: 'content_cta_start',
      })
      window._paq?.push([
        'trackEvent',
        'content_cta_start',
        'content_cta_start',
      ])
    }
  }, [isFormTouched])

  return (
    <Box
      variant="card.primary"
      sx={{
        px: [4, null, null, 6],
        pt: [3, null, null, 5],
        pb: [3, null, null, 1],
        maxWidth: 380,
        color: 'white',
        backgroundColor: 'darkNavy100',
        // backgroundImage: `url('${backgroundImage}')`,
        // backgroundPosition: 'center center',
        // backgroundSize: 'cover',
        // backgroundRepeat: 'no-repeat',
      }}
    >
      {showSuccess ? (
        <Text pb={[1, null, null, 4]}>
          Ihre Anfrage wurde erfolgreich an uns gesendet. Überprüfen Sie Ihr
          Emailpostfach für die weiteren Schritte.
        </Text>
      ) : (
        <>
          <Heading.H4 sx={{ color: 'white' }}>
            Machen Sie mehr aus Ihrer Immobilie!
          </Heading.H4>

          <Box as="form" onSubmit={handleSubmit(onSubmit)} sx={{ pt: 4 }}>
            <Space mb={3}>
              <Box>
                <LocationSelect
                  value={selectedCountry?.value}
                  onChange={setSelectedCountry}
                />
              </Box>
              <Box>
                <Input
                  type="hidden"
                  name="form_version"
                  value="1"
                  ref={register}
                />
                <Input
                  placeholder="Vorname"
                  name="first_name"
                  ref={register({ required: 'Bitte Vornamen angeben' })}
                  error={errors.first_name}
                  onClick={onFormTouch}
                />
              </Box>
              <Box>
                <Input
                  placeholder="Nachname"
                  name="last_name"
                  ref={register({ required: 'Bitte Nachnamen angeben' })}
                  error={errors.last_name}
                  onClick={onFormTouch}
                />
              </Box>
              <Box>
                <Input
                  placeholder="E-Mail"
                  name="email"
                  ref={register({ required: 'Bitte E-Mail angeben' })}
                  error={errors.email}
                  onClick={onFormTouch}
                />
              </Box>
              <Box
                sx={{
                  display: 'grid',
                  gridTemplateColumns: '112px auto',
                  gridTemplateRows: 'auto auto',
                  gridTemplateAreas: `
                    'telephonePrefix telephone'
                    'errorMessage errorMessage'
                  `,
                }}
              >
                <Box sx={{ gridArea: 'telephonePrefix' }}>
                  <Controller
                    as={CountryCodeSelect}
                    name="telephone_prefix"
                    register={register}
                    control={control}
                    defaultValue={countryCode === 'DE' ? '+49' : '+43'}
                  />
                </Box>
                <Input
                  placeholder="Telefonnummer"
                  name="telephone"
                  ref={register}
                  error={errors.telephone}
                  onClick={onFormTouch}
                  style={{
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0,
                    borderLeft: 'none',
                    gridArea: 'telephone',
                  }}
                />
              </Box>
              <Select
                options={SALUTATION_OPTIONS}
                onChange={(option) => {
                  setValue('salutation', option.value, {
                    shouldValidate: true,
                  })
                }}
                placeholder="Anrede"
                hasError={errors.salutation}
                isSearchable={false}
              />
              {errors.salutation && (
                <ErrorMessage message={errors.salutation.message} />
              )}
              <Box>
                <Select
                  options={PROPERTY_TYPE_OPTIONS}
                  onChange={(option) => {
                    setValue('property_type', option.value, {
                      shouldValidate: true,
                    })
                  }}
                  placeholder="Art der Immobilie"
                  hasError={errors.property_type}
                  isSearchable={false}
                />
                {errors.property_type && (
                  <ErrorMessage message={errors.property_type.message} />
                )}
              </Box>
              <Box>
                <Input
                  placeholder="PLZ"
                  name="zip"
                  ref={register}
                  error={errors.zip}
                />
              </Box>
              <Box>
                <Checkbox
                  label="Hiermit bestätige ich bis auf Widerruf, dass Bambus meine Daten zur Bearbeitung meiner Anfrage speichern darf."
                  name="consent_box"
                  ref={register({ required: 'Bitte einwilligen' })}
                  error={errors.consent_box}
                  alignItemsCenter
                  onDarkBase
                />
              </Box>
              <Box>
                <Checkbox
                  label={
                    <span>
                      <i>Optional: </i>
                      Hiermit bestätige ich bis auf Widerruf, dass Bambus meine
                      Daten für Marketing Zwecke speichern darf.
                    </span>
                  }
                  name="gdpr_consent"
                  ref={register}
                  error={errors.gdpr_consent}
                  alignItemsCenter
                  onDarkBase
                />
              </Box>
              {showError && <SubmissionFailedErrorMessage />}
              <Box py={2}>
                <Button
                  variant="v2Main3"
                  width="100%"
                  type="submit"
                  loading={loading}
                  disabled={loading}
                >
                  Anfrage senden
                </Button>
              </Box>
            </Space>
          </Box>
        </>
      )}
    </Box>
  )
}

export default LeadForm
